<template>
    <div class="bodys Tda">
        <Header></Header>

        <main>
            <!-- section-TDA首屏 -->
            <section class="section-hero">
                <div class="hero">
                    <div class="tda-description-box">
                        <div class="tda-text-box">
                            <h1 class="heading-primary">诊断性测试(TDA)</h1>
                            <p class="tda-description">
                                为贯彻全国教育大会精神，积极响应《国务院关于深化考试招生制度改革的实施意见》《深化新时代教育评价改革总体方案》《教育部关于加强和改进普通高中学生综合素质评价的意见》等文件要求，CSSAT设置诊断性测试(TDA)评价序列。
                            </p>
                            <p class="tda-description">
                                <strong>诊断性测试(TDA，Targeted Diagnostic Assessment)</strong
                                >是以中国高考评价体系、普通高中课程方案和各个学科课程标准为依据，结合大学考查视角对学生提供对接新高考模式的诊断性评价，旨在通过科学的、客观的过程性评价，促进中学生学科素养提升，助力大中衔接。
                            </p>
                            <p class="tda-description">
                                根据2017-2024年历次诊断性测试数据的分析结果显示，考生成绩与排名具有良好的区分度与高考成绩及高中学业水平之间存在良好的一致性。
                            </p>
                        </div>
                        <div class="tda-schedule-box">
                            <p class="tda-schedule-title">
                                <i class="ph ph-calendar-check schedule-icon"></i>
                                <span>考试时间安排</span>
                            </p>
                            <div class="tda-date-boxes">
                                <div class="date-box">
                                    <p class="test-date"><span>2024年9月19-20日</span></p>
                                    <p class="test-name">TDA-G11(高二)</p>
                                </div>
                                <div class="date-box">
                                    <p class="test-date"><span>2024年10月31-11月1日</span></p>
                                    <p class="test-name">TDA-G12(高三)</p>
                                </div>
                                <div class="date-box">
                                    <p class="test-date"><span>2024年12月下旬(拟)</span></p>
                                    <p class="test-name">TDA-G12(高三)</p>
                                </div>
                                <div class="date-box">
                                    <p class="test-date"><span>2025年2月下旬(拟)</span></p>
                                    <p class="test-name">TDA-G12(高三)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hero-bottom">
                        <div class="hero-buttons">
                            <a href="https://school.cssatest.com" class="btn btn--full"
                            >已有账号，登录中学系统报名 &rarr;</a
                            >
                            <a href="#register-subsection" class="btn btn--full"
                            >无账号，申请中学系统账号 &rarr;</a
                            >
                        </div>
                        <p class="bottom-tips">
                            *TDA测试仅支持以中学为单位报名。报名需在报名系统中完成，先前未参试中学需先通过考试项目组申请系统账号。
                        </p>
                    </div>
                </div>
            </section>

            <!-- section-考试类别 -->
            <section class="section-grade-type section">
                <div class="container">
                    <h2 class="heading-secondary heading-secondary--grade-type">
                        <p class="section-title">
                            <span class="section-title-text">诊断性测试(TDA)类别</span>
                        </p>
                    </h2>
                    <div class="grade-type-tables grid grid--2-cols">
                        <!-- TDA-G11(高二)序列 -->
                        <div class="grade-type-table g-11">
                            <p class="grade-type-title">
                                <span class="grade-type-title-text">TDA-G11(高二)</span>
                            </p>
                            <p class="grade-type-target">
                                旨在检验高一阶段知识和能力掌握程度，并让学生体会高考考查形式，帮助中学和学生提前在教学和学习上做好高考衔接。
                            </p>
                            <ul class="grade-type-properties">
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-users icon--list"></i>
                                    <span
                                    ><strong class="item-title">面向群体：</strong
                                    >高二学生</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-book icon--list"></i>
                                    <span
                                    ><strong class="item-title">科目设置：</strong
                                    >语文、数学、英语、物理、化学、生物、历史、地理、政治</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-hand-pointing icon--list"></i>
                                    <span
                                    ><strong class="item-title">选科方式：</strong
                                    >采用全科考查方式</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-graph icon--list"></i>
                                    <span>
                    <strong class="item-title"> 考查范围： </strong
                    >基于普通高中课程方案和各个学科课程标准及各地高一阶段普遍的教学进度归纳的统一知识点和能力框架
                  </span>
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-plus-square icon--list"></i>
                                    <span><strong class="item-title">加试科目：</strong>无</span>
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-user-check icon--list"></i>
                                    <span
                                    ><strong class="item-title">报名方式：</strong
                                    >仅支持中学为单位报名</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-pencil-simple-line icon--list"></i>
                                    <span
                                    ><strong class="item-title">考试形式：</strong
                                    >线下纸笔或线上机考</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-chart-line-up icon--list"></i>
                                    <span
                                    ><strong class="item-title">数据服务：</strong
                                    >数据型试题讲义、成绩表格、中学综合分析报告、中学学科分析报告、学生基础报告、学生预测报告、认证成绩单(仅限机考)</span
                                    >
                                </li>
                            </ul>
                        </div>

                        <!-- TDA-G12(高三)序列 -->
                        <div class="grade-type-table g-12">
                            <p class="grade-type-title">
                                <span>TDA-G12(高三)</span>
                            </p>
                            <p class="grade-type-target">
                                旨在检验学生在高三各复习阶段的学科表现，并为数理单科特长学生提供加试科目考查，助力高考冲刺及高校特殊类型考试准备。
                            </p>
                            <ul class="grade-type-properties">
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-users icon--list"></i>
                                    <span
                                    ><strong class="item-title">面向群体：</strong
                                    >高三学生(加试科目适合数理特长学生)</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-book icon--list"></i>
                                    <span
                                    ><strong class="item-title">科目设置：</strong
                                    >语文、数学、英语、物理、化学、生物、历史、地理</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-hand-pointing icon--list"></i>
                                    <span
                                    ><strong class="item-title">选科方式：</strong
                                    >按高考改革区选科方式</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-graph icon--list"></i>
                                    <span
                                    ><strong class="item-title">考查范围：</strong
                                    >基于普通高中课程方案和各个学科课程标准的高考知识点和能力框架，考查内容和形式完全贴合高考(加试科目除外)</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-plus-square icon--list"></i>
                                    <span
                                    ><strong class="item-title">加试科目：</strong
                                    >数理探究科目</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-user-check icon--list"></i>
                                    <span
                                    ><strong class="item-title">报名方式：</strong
                                    >仅支持中学为单位报名</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-pencil-simple-line icon--list"></i>
                                    <span
                                    ><strong class="item-title">考试形式：</strong
                                    >线下纸笔或线上机考</span
                                    >
                                </li>
                                <li class="grade-type-item list-item">
                                    <i class="ph ph-chart-line-up icon--list"></i>
                                    <span
                                    ><strong class="item-title">数据服务：</strong
                                    >数据型试题讲义、成绩表格、中学综合分析报告、中学学科分析报告、学生基础报告、学生预测报告、认证成绩单(仅限机考)</span
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <!-- section-考试服务介绍 -->
            <section class="section-intro section" id="section-intro">
                <div class="container">
                    <h2 class="heading-secondary heading-secondary--features">
                        <p class="section-title">
                            <span class="section-title-text">TDA特点</span>
                        </p>
                    </h2>

                    <!-- TDA常规科目试题介绍 -->
                    <div class="normal-subject-dev-intro intro">
                        <h3 class="intro-title heading-tertiary">
                            <span class="intro-title-number">01</span>
                            <span class="intro-title-text"
                            > 常规科目高度还原高考，为学生提供高质量模拟测试
              </span>
                        </h3>
                        <div class="intro-content">
                            <div class="normal-subject-section intro-section">
                                <div class="service">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>常规科目</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >TDA常规科目(非加试科目)适合广大高二或高三学生。试题命制严格以中国高考评价体系、普通高中课程方案和各个学科课程标准为依据，考查形式紧贴高考，试题难度适中且具备高区分度、信度和效度。命题专家团队是高质量命题的基本保障，拥有近200名成员，具备十年以上教学经验，五届以上高三教学经验，并参与过多次省、市、区级大规模考试原创命题工作。
                      </span>
                                        </p>
                                        <div class="subject-resource">
                                            <p class="tips">
                                                *点击按钮直接下载常规科目样卷或扫描二维码购买历史真题
                                            </p>
                                            <a
                                                    href="https://www.cssatest.com/example/常规科目样卷.zip"
                                                    class="btn btn--full btn--download btn--link"
                                            >
                                                <span>下载常规科目样卷</span>
                                                <i
                                                        class="ph-bold ph-download-simple icon--download"
                                                ></i>
                                            </a>
                                            <img
                                                    class="subject-excercise-qrcode"
                                                    src="../assets/img/tda-dev/subject-excercise-qrcode.png"
                                                    alt="历史试卷购买网页二维码"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="service-features">
                                    <p class="service-features-title">试题特点</p>
                                    <div class="service-feature-cards">
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">价值引领</p>
                                            <p class="service-feature-text">
                                                试题体现正确的政治方向和价值取向，引导学生形成正确的世界观、人生观和价值观
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">考查全面</p>
                                            <p class="service-feature-text">
                                                试题内容符合新高考趋势，对学生学科素养、关键能力和必备知识进行全面考查
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">科学性和时代性</p>
                                            <p class="service-feature-text">
                                                试题结合当前社会、科技、文化发展，考查对新知识和技能的掌握和应用能力
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">基础性和综合性</p>
                                            <p class="service-feature-text">
                                                试题涵盖高中段基础知识，同时考查综合运用知识解决实际问题的能力
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">应用性和创新性</p>
                                            <p class="service-feature-text">
                                                试题中设计实际应用场景，考查学生创新能力和运用理论知识解决问题能力
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">情境设计</p>
                                            <p class="service-feature-text">
                                                试题融入生活、学习等实际情境，考查学生在具体情境中运用知识解决问题能力
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- TDA加试科目试题介绍 -->
                    <div class="addition-subject-dev-intro intro">
                        <h3 class="intro-title heading-tertiary">
                            <span class="intro-title-number">02</span>
                            <span class="intro-title-text"
                            > 加试科目聚焦数理探究，让学生熟悉大学考查视角
              </span>
                        </h3>
                        <div class="intro-content">
                            <div class="addition-subject-section intro-section">
                                <div class="service">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>加试科目</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >TDA加试科目适合具备数学或物理单科特长的或有志于参加各高校相关学术水平考试的学生参加。试题充分体现大学考查视角，旨在全面评估学生是否具备大学新生所需的学科素养、专业知识及综合能力。加试科目的命题专家均为985高校数理方向相关专业的教授，确保了试题的专业性和权威性。
                      </span>
                                        </p>
                                        <div class="subject-resource">
                                            <a
                                                    href="https://www.cssatest.com/example/加试科目样卷.zip"
                                                    class="btn btn--full btn--download"
                                            >
                                                <span>下载数理探究(加试)科目样卷</span>
                                                <i
                                                        class="ph-bold ph-download-simple icon--download"
                                                ></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-features">
                                    <p class="service-features-title">试题特点</p>
                                    <div class="service-feature-cards">
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">注重基础</p>
                                            <p class="service-feature-text">
                                                试题着重考查学科基础知识，注重对知识核心概念和原理的透彻理解及灵活运用
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">综合性强</p>
                                            <p class="service-feature-text">
                                                试题突出学科交叉与融合，考查综合运用知识的能力和跨学科思维的深度与广度
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">创新性强</p>
                                            <p class="service-feature-text">
                                                试题形式新颖，强调考查学生的创新思维和利用创新思维解决问题的能力
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">深度与难度较高</p>
                                            <p class="service-feature-text">
                                                试题要求对知识本质和内在联系的深入理解，充分评估学生的数理学科潜质
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbt-sectinon intro-section">
                                <div class="service service--cbt">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>线上机考服务</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >线上机考模式由于其更大程度保障了考试严肃性且实施更便利等特点，正被越来越多高水平大学“强基计划”及其他各类“大学校考”采用。为让考生更熟悉线上机考形式，TDA除提供线下纸笔考试形式，还提供线上机考形式。数理探究科目(加试科目)仅为参与线上机考考生提供，线上机考考生可参与常规及数理探究两种类型科目的考查。
                      </span>
                                            <span class="paragraph">
                        TDA线上机考遵循严肃性“大学校考”实施框架，并由于其最大程度保障了<a
                                                    class="text-link text-link--cbt"
                                                    href="#supplement-features-cbt"
                                            ><i class="ph ph-link text-icon"></i
                                            >评估结果可靠和有效性</a
                                            >，对于线上机考考生支持<a
                                                    class="text-link text-link--cbt"
                                                    href="#certification-intro"
                                            ><i class="ph ph-link text-icon"></i>认证成绩单申请</a
                                            >。
                      </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 成绩保障介绍 -->
                    <div class="implement-intro intro">
                        <h3 class="intro-title heading-tertiary">
                            <span class="intro-title-number">03</span>
                            <span class="intro-title-text"
                            > 高严肃性考试实施标准，保障评估结果可信、有效</span
                            >
                        </h3>
                        <div class="intro-content">
                            <div class="procedure-section intro-section">
                                <div class="service">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>高严肃性标准化考试实施</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >TDA采用高严肃性考试实施标准，通过对内标准化保障机制、对外标准化实施指引、信息技术、人工智能等多种手段，系统性保障考试试题质量、考试安全性、稳定性、公平性和数据可靠性，从而让评估结果具备更高可信度和有效性。
                      </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="service-features">
                                    <p class="service-features-title">标准化考试服务保障</p>
                                    <div class="service-feature-cards">
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">试题质量保障</p>
                                            <p class="service-feature-text">
                                                题目命制严格遵循“命、审、校(初)、排、校(终)”流程，分工明确，且试题均经过严格的原创性检查
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">试题安全保障</p>
                                            <p class="service-feature-text">
                                                制题人员签订保密协议，制题全程采用“背对背”原则，且试题与答题卡均采用带密码锁的保密袋(箱)运输
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">实施质量保障</p>
                                            <p class="service-feature-text">
                                                为中学各类实施角色提供针对性的、明晰的实施指引，并采用信息化管理系统对考试全流程进行管理
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">考试公平性保障</p>
                                            <p class="service-feature-text">
                                                统一发放保密袋(箱)开锁密码和机考开考密钥确保统一开考，阅卷采用线上“联考联阅”模式
                                            </p>
                                        </div>
                                        <div class="service-feature-card">
                                            <p class="service-feature-title">数据可靠性保障</p>
                                            <p class="service-feature-text">
                                                对报名、参试、扫描等数据进行校验和确认，并对成绩进行多轮自动化检查和5‰随机抽样手工核对
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="supplement" id="supplement-features-cbt">
                                <p class="supplement-title">采用多种技术手段增强机考成绩质量</p>
                                <div class="cbt-procedure-section intro-section">
                                    <div class="cbt-features service-features">
                                        <ul class="cbt-feature-list">
                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>采用多种加密算法对机考电子化试题加密</span>
                        </span>
                                                <span class="cbt-feature-tag tag--safe"
                                                >安全性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>采用多层加密进行数据安全传输防攻击措施</span>
                        </span>
                                                <span class="cbt-feature-tag tag--safe"
                                                >安全性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>机考全程实时监控，确保考试过程可回溯</span>
                        </span>
                                                <span class="cbt-feature-tag tag--fair"
                                                >公平性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>机考系统自动屏蔽与考试无关的应用程序</span>
                        </span>
                                                <span class="cbt-feature-tag tag--fair"
                                                >公平性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>机考软件锁屏、支持自动抓取切屏画面和预警</span>
                        </span>
                                                <span class="cbt-feature-tag tag--fair"
                                                >公平性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>答案多途径回收、一致性检查兼防篡改校验</span>
                        </span>
                                                <span class="cbt-feature-tag tag--realiability"
                                                >可靠性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>

                                            <li class="cbt-feature-list-item list-item">
                        <span class="cbt-feature-text">
                          <i class="ph ph-check icon--list"></i>
                          <span>考生作答完整行为数据实时保存，考后可回溯</span>
                        </span>
                                                <span class="cbt-feature-tag tag--realiability"
                                                >可靠性<i
                                                        class="ph ph-arrow-fat-line-up text-icon"
                                                ></i
                                                ></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="img-box">
                                        <img
                                                class="cbt-procedure-img"
                                                src="../assets/img/data/学生报告.webp"
                                                alt="真实的机考实施相关图片"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 认证成绩单介绍 -->
                    <div class="certification-intro intro" id="certification-intro">
                        <h3 class="intro-title heading-tertiary">
                            <span class="intro-title-number">04</span>
                            <span class="intro-title-text"
                            > 考试成绩认证，供学生向成绩采信方提交成绩证明</span
                            >
                        </h3>
                        <div class="intro-content">
                            <div class="certification-section intro-section">
                                <div class="service service--cbt">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>认证成绩单(仅限线上机考)</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >参加TDA线上机考的考生可在学生系统中申请认证成绩单。认证成绩单是TDA考试的防伪成绩证明，可向成绩采信方提交。认证成绩单分为纸质版和电子版两种，申请成功后两种形式可同时获取。
                      </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="certification-features service-features">
                                    <!-- 认证成绩单本身特点介绍 -->
                                    <figure class="service-feature-figure--vertical">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">认证成绩单</p>
                                            <p class="service-feature-text">
                                                认证成绩单内容简洁明了，主要体现考生成绩及各地域范围内的排名数据。纸质认证成绩单纸张质量高、印刷清晰、包装精致。
                                            </p>
                                        </div>
                                        <img
                                                class="certif-img service-feature-figure-img"
                                                src="../assets/img/tda-cert/report.webp"
                                                alt="认证成绩单与包装示意图"
                                        />
                                    </figure>

                                    <!-- 认证成绩单数据验证特点介绍 -->
                                    <figure class="service-feature-figure--vertical">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">成绩数据验证</p>
                                            <p class="service-feature-text">
                                                为保证认证成绩单成绩真实性和可靠性，成绩单设有唯一的认证二维码和认证编号，使用者可在<a
                                                    class="text-link text-link--cbt"
                                                    href="https://yanzheng.cssatest.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                            ><i class="ph ph-link text-icon"></i>成绩单验证网站</a
                                            >中对成绩单数据进行验证。
                                            </p>
                                        </div>
                                        <img
                                                class="certif-img service-feature-figure-img"
                                                src="../assets/img/tda-cert/validation.png"
                                                alt="成绩单数据验证网站示意图"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 数据分析报告介绍 -->
                    <div class="data-intro intro">
                        <h3 class="intro-title heading-tertiary">
                            <span class="intro-title-number">05</span>
                            <span class="intro-title-text"
                            > 分析数据详实，为教学和备考提供强指引</span
                            >
                        </h3>
                        <div class="intro-content">
                            <div class="school-report-section intro-section">
                                <div class="service">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>考试分析报告-中学版</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >中学考试分析报告旨在帮助参试中学根据评估结果明确教学提升方向，进而有针对性地优化教学策略。中学考试分析报告分为综合报告和分科报告。综合报告旨在让教学管理者了解本校总成绩及学科成绩整体表现，在宏观上把握教学情况。分科报告旨在让学科老师了解各学科学生的整体表现，从而明确教学方向、优化教学策略。
                      </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="school-report-features service-features">
                                    <p class="service-features-title">中学报告图表介绍</p>
                                    <!-- 中学报告特点1 -->
                                    <figure class="service-feature-figure--horizon">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">成绩表现概况</p>
                                            <p class="service-feature-text">
                                                呈现各学科平均分、标准差(系数)、平均分和优生在全国或全省范围的校级横向对比等数据，让教学管理者多角度洞察本校整体成绩表现
                                            </p>
                                        </div>
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-1.webp"
                                                alt="中学报告成绩表现概况示意图"
                                        />
                                    </figure>

                                    <!-- 中学报告特点2 -->
                                    <figure
                                            class="service-feature-figure--horizon horizon-figure--reversed"
                                    >
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-2.webp"
                                                alt="中学报告学科平均表现示意图"
                                        />
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">学科平均表现</p>
                                            <p class="service-feature-text">
                                                呈现各学科平均分及本校平均分相对于本省考生平均分、本身优生平均分和全国优生平均分的相对位置，让教学管理者明确本校学科优劣势
                                            </p>
                                        </div>
                                    </figure>

                                    <!-- 中学报告特点3 -->
                                    <figure class="service-feature-figure--horizon">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">成绩分布</p>
                                            <p class="service-feature-text">
                                                呈现“纸笔”和“机考”两类考生总成绩和各学科的成绩分布，让教学管理者直观地了解本校成绩的整体集中度和差异度，及两类考生的差异程度
                                            </p>
                                        </div>
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-3.webp"
                                                alt="中学报告成绩分布示意图"
                                        />
                                    </figure>

                                    <!-- 中学报告特点4 -->
                                    <figure
                                            class="service-feature-figure--horizon horizon-figure--reversed"
                                    >
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-4.webp"
                                                alt="中学报告知识点表现概况示意图"
                                        />
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">知识点表现概况</p>
                                            <p class="service-feature-text">
                                                呈现各知识点本校平均得分率、考查频率、及全国和本省范围的整体或优生平均得分率，让学科老师明确学科知识点优劣势和教学重点
                                            </p>
                                        </div>
                                    </figure>

                                    <!-- 中学报告特点5 -->
                                    <figure class="service-feature-figure--horizon">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">知识点表现对比</p>
                                            <p class="service-feature-text">
                                                通过箱型图呈现各学科知识点下本省各中学平均得分率的分布，了解本校平均得分率的相对位置，让学科老师从知识点视角了解科目优劣势
                                            </p>
                                        </div>
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-5.webp"
                                                alt="中学报告知识点表现对比示意图"
                                        />
                                    </figure>

                                    <!-- 中学报告特点6 -->
                                    <figure
                                            class="service-feature-figure--horizon horizon-figure--reversed"
                                    >
                                        <img
                                                class="service-feature-figure-img"
                                                src="../assets/img/tda-data/school-report-6.webp"
                                                alt="中学报告作答时长示意图"
                                        />
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">作答时长</p>
                                            <p class="service-feature-text">
                                                呈现线上机考考生作答时在各题作答界面的停留时长(可近似看作答题时长)，让学科老师了解学生作答时长分配从而识别学生作答策略问题
                                            </p>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div class="student-report-section intro-section">
                                <div class="service">
                                    <p class="service-title">
                                        <i class="ph ph-lightbulb"></i>
                                        <span>考试分析报告-学生版</span>
                                    </p>
                                    <div class="service-content">
                                        <p class="service-text">
                      <span class="paragraph"
                      >TDA向参试学生提供学生考试分析报告作为个人学习的参考，旨在帮助参试学生根据评估结果明确高考或“强基计划”备考方向，进而有针对性地优化学习策略。
                      </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="student-report-features service-features">
                                    <figure class="service-feature-figure--vertical">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">基础报告</p>
                                            <p class="service-feature-text">
                                                基础报告内容为学生当次测试的客观数据，包含学生在当次测试中的总成绩、单科成绩，及成绩在全国、本省、本校范围内的排名情况，并包括各科的各题型、各知识点下的数据表现
                                            </p>
                                        </div>
                                        <img
                                                class="student-report-img service-feature-figure-img"
                                                src="../assets/img/tda-data/基础报告.webp"
                                                alt="基础报告示意图"
                                        />
                                    </figure>
                                    <figure class="service-feature-figure--vertical">
                                        <div class="service-feature-figure-desc">
                                            <p class="service-feature-title">预测报告</p>
                                            <p class="service-feature-text">
                                                预测报告内容以学生考试客观数据为基础并通过统计推断得出的推断类型数据，包括单科高考预测分和百分比排名(标测数据指标)，以及基于标测数据得出的“强基计划”入围概率
                                            </p>
                                        </div>
                                        <img
                                                class="student-report-img service-feature-figure-img"
                                                src="../assets/img/tda-data/预测报告.webp"
                                                alt="预测报告示意图"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- section-参加TDA -->
            <section class="section-procedure section">
                <div class="container">
                    <h2 class="heading-secondary heading-secondary--procedure">
                        <p class="section-title">
                            <span class="section-title-text">参加诊断性测试(TDA)</span>
                        </p>
                    </h2>
                    <div class="section-content">
                        <!-- TDA参与全流程 -->
                        <div class="procedure-subsection">
                            <h3 class="procedure-subsection-title heading-tertiary">
                <span class="procedure-subsection-title-text"
                >TDA参试与服务流程</span
                >
                            </h3>
                            <div class="procedure-subsection-content subsection-content">
                                <!-- step1 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-one step-number"></i>
                                        <span>考试报名</span>
                                    </p>
                                    <p class="step-content">
                                        在CSSAT中学系统中导入参试学生名单信息完成考试报名。如未获得中学系统的账号和密码，可填写<a
                                            class="text-link"
                                            href="#register-subsection"
                                    ><i class="ph ph-link text-icon"></i>报名申请信息</a
                                    >
                                        进行网上申请或直接<a class="text-link" href="#footer"
                                    ><i class="ph ph-link text-icon"></i>联系考试项目组</a
                                    >
                                    </p>
                                </div>

                                <!-- step2 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-two step-number"></i>考前准备
                                    </p>
                                    <p class="step-content">
                                        接收考试项目组寄送的试卷、答题卡及其他相关物料，并阅读各角色考试实施指引熟悉相关实施操作。如参与线上机考，需提前按要求完成监控布置和考试软件准备
                                    </p>
                                </div>

                                <!-- step3 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-three step-number"></i
                                        >考中实施
                                    </p>
                                    <p class="step-content">
                                        考前及时接收保密袋(箱)开锁密码、开袋(箱)获取并分发试卷和答题卡。如参加机考，需获取并公布开考密钥。考中认真监考，如遇异常与考试项目组沟通解决
                                    </p>
                                </div>

                                <!-- step4 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-four step-number"></i>数据回传
                                    </p>
                                    <p class="step-content">
                                        考试结束后配合考试项目组完成答题卡扫描与上传。如参加机考，配合考试项目组完成考生机考作答数据回收，并在规定时间内向考试项目组上传本地监控录制视频文件
                                    </p>
                                </div>

                                <!-- step5 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-five step-number"></i>线上阅卷
                                    </p>
                                    <p class="step-content">
                                        及时接收考试项目组分发的阅卷参考答案与评分标准、各科阅卷账号和密码，并在考后及时完成线上阅卷。如对答案有疑义，及时向考试项目组写邮件告知
                                    </p>
                                </div>

                                <!-- step6 -->
                                <div class="step">
                                    <p class="step-title">
                                        <i class="ph ph-number-square-six step-number"></i
                                        >成绩与报告获取
                                    </p>
                                    <p class="step-content">
                                        考试当日内中学可获得客观题数据分析及完整试题解析。阅卷结束后当周获得成绩明细表格、各类分析报告。学生可获得考试分析报告或申请认证成绩单
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- TDA参与全流程 -->
                        <div class="register-subsection" id="register-subsection">
                            <div class="register-text-box">
                                <h2 class="heading-secondary">提交申请表进行考试报名申请</h2>
                                <p class="register-text">
                                    TDA报名需在CSSAT中学系统中完成。如还未有系统账号和密码，请先填写并提交以下申请表或直接联系考试项目组获取。提交申请后，考试项目组会与你联系并向你提供相关账号和密码。如已有中学系统账号和密码，请直接<a
                                        class="text-link"
                                        href="#"
                                ><i class="ph ph-link text-icon"></i>进入中学系统进行报名</a
                                >。
                                </p>
                                <div class="register-form" name="sign-up">
                                    <!-- 中学省份筛选 -->
                                    <div class="form-item">
                                        <label for="school-province">中学省份:</label>
                                        <select
                                                v-model="formData.province_id"
                                                id="school-proince-selection"
                                                name="school-proince-selection"
                                                required
                                                :class="!formData.province_id?'no-value':''"
                                                @blur="validateProvince"
                                                @change="onChangeProvince"
                                        >
                                            <option value="">请选择中学省份</option>
                                            <option v-for="(item, index) in provinceList"
                                                    :value="item.id"
                                            >{{item.name}}
                                            </option>
                                        </select>
                                        <span v-if="provinceErr" class="input-error">{{provinceErrMsg}}</span>
                                    </div>

                                    <!-- 中学城市筛选 -->
                                    <div class="form-item">
                                        <label for="school-city">中学城市:</label>
                                        <select
                                                v-model="formData.city_id"
                                                id="school-city-selection"
                                                name="school-city-selection"
                                                required
                                                :class="!formData.city_id?'no-value':''"
                                                @blur="validateCity"
                                        >
                                            <option value="">请选择中学城市</option>
                                            <option v-for="(item, index) in cityList"
                                                    :value="item.id"
                                            >{{item.name}}
                                            </option>
                                        </select>
                                        <span v-if="cityErr" class="input-error">{{cityErrMsg}}</span>
                                    </div>

                                    <!-- 中学名称筛选 -->
                                    <div class="form-item">
                                        <label for="school">中学名称:</label>
                                        <input
                                                v-model="formData.school_name"
                                                id="school-selection"
                                                type="text"
                                                placeholder="请填写真实中学名称"
                                                name="school-selection"
                                                required
                                                @blur="validateSchool"
                                        />
                                        <span v-if="schoolErr" class="input-error">{{schoolErrMsg}}</span>
                                    </div>

                                    <!-- 联系人姓名 -->
                                    <div class="form-item">
                                        <label for="name">联系人姓名:</label>
                                        <input
                                                v-model="formData.contact_person"
                                                id="input-name"
                                                type="text"
                                                placeholder="请填写真实联系人"
                                                name="input-email"
                                                required
                                                @blur="validatePerson"
                                        />
                                        <span v-if="contactPersonErr" class="input-error">{{contactPersonErrMsg}}</span>
                                    </div>

                                    <!-- 联系人邮箱 -->
                                    <div class="form-item">
                                        <label for="email">联系邮箱:</label>
                                        <input
                                                v-model="formData.email"
                                                id="input-email"
                                                type="email"
                                                placeholder="请填写常用邮箱"
                                                name="input-email"
                                                required
                                                @blur="validateEmail"
                                        />
                                        <span v-if="emailErr" class="input-error">{{emailErrMsg}}</span>
                                    </div>

                                    <!-- 联系人电话 -->
                                    <div class="form-item">
                                        <label for="phone">联系电话:</label>
                                        <input
                                                v-model="formData.contact_phone"
                                                id="input-phone"
                                                type="text"
                                                placeholder="请填写联系手机号或固话"
                                                name="input-phone"
                                                required
                                                @blur="validatePhone"
                                        />
                                        <span v-if="phoneErr" class="input-error">{{phoneErrMsg}}</span>
                                    </div>

                                    <button class="btn btn--form" @click="onApply()">提交申请</button>
                                    <div class="register-dialog" v-if="errMsgShow">{{errMsg}}</div>
                                </div>
                            </div>
                            <div
                                    class="register-img-box"
                                    role="img"
                                    aria-label="填写申请表的配图"
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from './UI/Header';
    import Footer from './UI/Footer';
    import {getProvinceList, getCityList, apply} from '@/request';

    export default {
        name: 'Tda',
        metaInfo: {
            title: "CSSAT、CSSATEST、TDA-标准学术能力测试（诊断性测试）",
            meta: [
                {
                    name: 'description',
                    content: '诊断性测试(TDA，Targeted Diagnostic Assessment)是以中国高考评价体系、普通高中课程方案和各个学科课程标准为依据，结合大学选才视角对学生提供对接新高考模式的诊断性评价，旨在通过科学的、客观的过程性评价，促进中学生学科素养提升，助力大中衔接。'
                }
            ]
        },
        components: {
            Header,
            Footer
        },
        data() {
            return {
                loading: false,
                formData: {
                    province_id: '',
                    city_id: '',
                    school_name: '',
                    contact_person: '',
                    email: '',
                    contact_phone: '',
                    action: 'gw',
                },
                provinceErr: false,
                provinceErrMsg: '',
                cityErr: false,
                cityErrMsg: '',
                schoolErr: false,
                schoolErrMsg: '',
                contactPersonErr: false,
                contactPersonErrMsg: '',
                emailErr: false,
                emailErrMsg: '',
                phoneErr: false,
                phoneErrMsg: '',
                errMsgShow: false,
                errMsg: '',
                cityOption: {},
                provinceList: [],
                cityList: [],
            }
        },
        mounted() {
            let hash = this.$route.hash;
            if (hash) {
                hash = hash.slice(1)
                this.scrollToElement(hash);
            }
            this.getProvince();
            this.getCity();


        },
        watch: {
            '$route'(to, from) {
                let hash = to.hash;
                if (hash) {
                    hash = hash.slice(1)
                    this.scrollToElement(hash);
                }
                console.log('路由变化了！', from.path, '->', to.path)
            }
        },
        methods: {
            // 邮箱正则
            ruleEmail(email) {
                let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                return emailRegex.test(email);
            },
            // 手机号|固话正则
            rulePhone(phone) {
                // 匹配手机号的正则
                const phoneRegex = /^(\+?\d{1,4})?\s*-?\s*(\(?\d{3,4}\)?)\s*-?\s*(\d{3,4})\s*-?\s*(\d{4})$/;
                // 匹配固定电话的正则
                const fixedPhoneRegex = /^(\+?\d{1,4})?\s*-?\s*(\d{3,4})\s*-?\s*(\d{3,4})$/;
                return phoneRegex.test(phone) || fixedPhoneRegex.test(phone);
            },
            validateProvince() {
                if (!this.formData.province_id) {
                    this.provinceErr = true;
                    this.provinceErrMsg = '请选择中学省份！';
                    return true;
                } else {
                    this.provinceErr = false;
                    this.provinceErrMsg = '';
                    return false;
                }
            },
            validateCity() {
                if (!this.formData.city_id) {
                    this.cityErr = true;
                    this.cityErrMsg = '请选择中学城市！';
                    return true;
                } else {
                    this.cityErr = false;
                    this.cityErrMsg = '';
                    return false;
                }
            },
            validateSchool() {
                if (!this.formData.school_name) {
                    this.schoolErr = true;
                    this.schoolErrMsg = '请填写真实中学名称！';
                    return true;
                } else {
                    this.schoolErr = false;
                    this.schoolErrMsg = '';
                    return false;
                }
            },
            validatePerson() {
                if (!this.formData.contact_person) {
                    this.contactPersonErr = true;
                    this.contactPersonErrMsg = '请填写真实联系人！';
                    return true;
                } else {
                    this.contactPersonErr = false;
                    this.contactPersonErrMsg = '';
                    return false;
                }
            },
            validateEmail() {
                if (!this.formData.email) {
                    this.emailErr = true;
                    this.emailErrMsg = '请填写常用邮箱！';
                    return true;
                } else if (!this.ruleEmail(this.formData.email)) {
                    this.emailErr = true;
                    this.emailErrMsg = '请填写正确格式的邮箱！';
                    return true;
                } else {
                    this.emailErr = false;
                    this.emailErrMsg = '';
                    return false;
                }
            },
            validatePhone() {
                if (!this.formData.contact_phone) {
                    this.phoneErr = true;
                    this.phoneErrMsg = '请填写联系手机号或固话！';
                    return true;
                } else if (!this.rulePhone(this.formData.contact_phone)) {
                    this.phoneErr = true;
                    this.phoneErrMsg = '请填写正确格式的手机号或固话！';
                    return true;
                } else {
                    this.phoneErr = false;
                    this.phoneErrMsg = '';
                    return false;
                }
            },
            // 提交申请
            async onApply() {
                let status = false;
                if (this.validateProvince()) {
                    status = true;
                }
                if (this.validateCity()) {
                    status = true;
                }
                if (this.validateSchool()) {
                    status = true;
                }
                if (this.validatePerson()) {
                    status = true;
                }
                if (this.validateEmail()) {
                    status = true;
                }
                if (this.validatePhone()) {
                    status = true;
                }
                if (status) return false;

                if (this.loading) return false;
                this.loading = true;

                let data = await apply(this.formData);
                this.errMsg = data.message;
                this.errMsgShow = true;
                setTimeout(() => {
                    this.errMsg = '';
                    this.errMsgShow = false;
                }, 3000)
                if (data.code != 200) {
                    this.loading = false;
                    return false;
                }
                this.loading = false;
                this.formData.province_id = '';
                this.formData.city_id = '';
                this.formData.school_name = '';
                this.formData.contact_person = '';
                this.formData.email = '';
                this.formData.contact_phone = '';
            },
            onChangeProvince() {
                this.formData.city_id = '';
                this.cityList = [];
                let pId = this.formData.province_id;
                let all_city = this.cityOption;
                let city_list = [];
                for (let key in all_city) {
                    if (pId == all_city[key]['parent_id']) {
                        city_list.push(all_city[key]);
                    }
                }
                this.cityList = city_list;
            },
            async getProvince() {
                let data = await getProvinceList();
                if (data.code != 200) return;
                this.provinceList = data.list;
            },
            async getCity() {
                let data = await getCityList();
                if (data.code != 200) return;
                this.cityOption = data.list;
            },
            scrollToElement(elementId) {
                const el = this.$el.querySelector(`#${elementId}`);
                if (el) {
                    window.scrollTo({
                        top: el.offsetTop,
                        behavior: 'smooth'
                    });
                }
            },
            downloadFile(name) {
                let href = parent.window.location.origin + '/example/' + name;
                console.log(href);
                window.location.href = href;
            }
        }
    }
</script>

<style scoped lang="css">
    @import '../assets/css/main--tda.css';
    @import '../assets/css/queries--tda.css';

    .form-item {
        position: relative;
    }

    .input-error {
        position: absolute;
        top: 100%;
        left: 0;
        color: #f56c6c;
        font-size: 1.1rem;
        line-height: 1;
        padding-top: 0.4rem;
    }

    .register-form {
        position: relative;
    }

    .register-dialog {
        position: absolute;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        background-color: #000000;
        padding: 1rem 1.2rem;
        border-radius: 0.6rem;
        opacity: 0.85;
    }
</style>
